import { PagedResponse, Property, Session, Tag, User, Visitor } from '../../models';
import { TicketReply } from './ticketreply.model';

export interface Ticket {
    id?: number;
    public_id?: number;
    subject?: string;
    recipient?: Visitor;
    assignees?: User[];
    replies?: TicketReply[];
    tags?: Tag[];
    property?: Property;
    linked_tickets?: Ticket[];
    linked_session?: Session;
    priority?: number;
    status?: number;
    closed_at?: string;
    created_at?: string;
    last_updated_at?: string;
    created_by?: string;
    unread: boolean;
    attachment?: string;
    attachments?: string[];
    summary?: string;
}


export class SingleTicketResponse extends Response {
    model: Ticket
}
  

export interface TicketsResponse extends PagedResponse{
    model: Array<Ticket>;
}

export interface ConversationSummary{
    summary: string
}

export interface ConversationSummaryResponse extends Response{
    model: ConversationSummary
}

export enum TicketsViewMode{
    All = 1,
    Mine = 2,
    Open = 3,
    InProgress = 4,
    Closed = 5
}

export enum TicketStatus{
    Open = 1,
    InProgress = 2,
    Solved = 3
}
