import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscription } from 'src/app/billing/models/subscription.model';
import { environment } from '../../../environments/environment';
import { CustomView, CustomViewResponse, CustomViewsResponse, Feedback, FeedbackResponse } from '../../models';
import { LogModel } from '../../models/log.model';
import { KbArticle, KbArticleResponse, KbArticlesResponse } from '../../kb/models/kbarticle.model';

const API_URL = environment.apiUrl;

@Injectable()
export class CoreService {

    constructor(
      private http: HttpClient) {
    }

    public saveFeedback(feedback: Feedback) : Observable<FeedbackResponse>  {
        return this.http.post<FeedbackResponse>(
            `${API_URL}/api/feedback`,
            feedback
          );
    }

    public log(log: LogModel) {
      this.http.post<Response>(`${API_URL}/api/session/logs`, log).subscribe(); 
    }

    public isPaidPlan(sub: Subscription){
      return ((sub.plan_id == 3) || (sub.plan_id == 4) || (sub.plan_id == 5) || (sub.plan_id == 6));
    }

    public getArticles(search: string = "", category_id: number = 0, property_id: number = 0) : Observable<KbArticlesResponse>  {
      return this.http.get<KbArticlesResponse>(`${API_URL}/api/kb/articles?search=${search}&category_id=${category_id}&property_id=${property_id}`);
    }
  
    public getArticle(article_id: number, summary: boolean = false) : Observable<KbArticleResponse>  {
      return this.http.get<KbArticleResponse>(`${API_URL}/api/kb/articles/${article_id}?summary=${summary}`);
    }

    public getCustomViews(org_id: number) : Observable<CustomViewsResponse>  {
      return this.http.get<CustomViewsResponse>(`${API_URL}/api/settings/custom_views?org_id=${org_id}`);
    }

    public getCustomView(id: number) : Observable<CustomViewResponse>  {
      return this.http.get<CustomViewResponse>(`${API_URL}/api/settings/custom_views/${id}`);
    }

    public saveCustomView(custom_view: CustomView, org_id: number) : Observable<CustomViewResponse>  {
      return this.http.post<CustomViewResponse>(
          `${API_URL}/api/settings/custom_views?org_id=${org_id}`,
          custom_view
        );
  }

  public deleteCustomView(id: number) : Observable<CustomViewResponse>  {
    return this.http.delete<CustomViewResponse>(`${API_URL}/api/settings/custom_views/${id}`);
  }

}