import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

import { savedRepliesReducer, SavedRepliesState } from './store/saved.replies.reducer';
import { propertiesReducer, PropertiesState } from './store/properties.reducer';
import { settingsReducer, SettingsState } from  '../settings/store/settings.reducers'
import { chatFlowsReducer, ChatFlowsState } from './store/chatflow.reducer';
import { layoutReducer, LayoutState } from './store/layout.reducer';
import { authReducer } from './auth';
import { AuthState } from './auth/auth.reducers';
import { visitorsReducer, VisitorState } from '../conversations/store/visitors.reducers';
import { ActivityState, activityReducer } from '../conversations/store/activity.reducer';
import { KbState, KbStateReducer } from '../kb/store/kb.reducers';
import { TicketsState, TicketsStateReducer } from '../tickets/store/tickets.reducer';

export interface AppState {
    activities: ActivityState,
    auth: AuthState,
    chatflows: ChatFlowsState,
    kb: KbState,
    layout: LayoutState,
    properties: PropertiesState,
    router: RouterReducerState<any>;
    savedreplies: SavedRepliesState,
    settings: SettingsState,
    tickets: TicketsState,
    visitors: VisitorState
 }

export const reducers: ActionReducerMap<AppState> = {
    activities: activityReducer,
    auth: authReducer,
    chatflows: chatFlowsReducer,
    kb: KbStateReducer,
    layout: layoutReducer,
    properties: propertiesReducer,
    router: routerReducer,
    savedreplies: savedRepliesReducer,
    settings: settingsReducer,
    tickets: TicketsStateReducer,
    visitors: visitorsReducer
};

export const metaReducers: MetaReducer<AppState>[] = [];
