import { createSelector, createFeatureSelector } from '@ngrx/store';
import { TicketsState } from './tickets.reducer';
import * as fromTickets from './tickets.reducer';

export const selectTicketsState = createFeatureSelector<TicketsState>('tickets');

export const selectTicketsProperty = createSelector(
    selectTicketsState,
    TicketsState => TicketsState.selectedProperty
);

export const selectSelectedTicketId = createSelector(
    selectTicketsState,
    TicketsState => TicketsState.selectedTicket
);

export const selectSelectedTicket = createSelector(
    selectTicketsState,
    selectSelectedTicketId,
    (TicketsState, selectTicketId) => TicketsState.entities[selectTicketId]
);

export const selectTickets = createSelector(
    selectTicketsState,
    fromTickets.selectAll
);

export const selectTicketsPaging = createSelector(
    selectTicketsState,
    TicketsStateReducer => TicketsStateReducer.paging
);

export const selectTicketById = ({ticket_id}) => createSelector(
    selectTicketsState,
    TicketsStateReducer => TicketsStateReducer.entities[ticket_id]
);

export const selectTicketsIsLoaded = createSelector(
    selectTicketsState,
    TicketsStateReducer => TicketsStateReducer.isLoaded
);

export const selectTicketSaveLoading = createSelector(
    selectTicketsState,
    TicketsStateReducer => TicketsStateReducer.save_loading
);

export const selectReplySending = createSelector(
    selectTicketsState,
    TicketsStateReducer => TicketsStateReducer.sending
);

export const selectTicketDeleteLoading = createSelector(
    selectTicketsState,
    TicketsStateReducer => TicketsStateReducer.delete_loading
);

export const selectView = createSelector(
    selectTicketsState,
    TicketsStateReducer => TicketsStateReducer.view
);

export const selectTicketLoading = createSelector(
    selectTicketsState,
    TicketsStateReducer => TicketsStateReducer.isTicketsLoading
);