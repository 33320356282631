
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
// Services
import {
	ContentAnimateDirective,
	FirstLetterPipe,
	GetObjectPipe,
	HeaderDirective,
	JoinPipe,
	MenuDirective,
	OffcanvasDirective,
	SafePipe,
	StickyDirective,
	TabClickEventDirective,
	TimeElapsedPipe,
	ToggleDirective
} from './_base/layout';
import { HasRoleDirective } from './hasrole.directive';
import { InlineSVGModule, InlineSVGDirective } from 'ng-inline-svg';
import { UniqueColorPipe } from './pipes/ucolor.pipe';
import { RocketEditModule } from './rocket-edit/rocket-edit.module';
import { MomentModule } from 'ngx-moment';
import { AttachmentImageComponent } from './attachment/attachmentimage.component';
import { PlatformFormatPipe } from './pipes/platformFormat';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageArticleComponent } from './message-article/message-article.component';
import { WhatsnewComponent } from './whatsnew/whatsnew.component';

@NgModule({
	imports: [
		CommonModule, 
		InlineSVGModule,
		RocketEditModule,
		MomentModule,
		ReactiveFormsModule,
		HasRoleDirective
	],
	declarations: [
		// directives
		HeaderDirective,
		OffcanvasDirective,
		ToggleDirective,
		MenuDirective,
		TabClickEventDirective,
			ContentAnimateDirective,
		StickyDirective,
		// pipes
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		SafePipe,
		FirstLetterPipe,
		WhatsnewComponent
	],
	exports: [
		// directives
		HeaderDirective,
		OffcanvasDirective,
		ToggleDirective,
		MenuDirective,
		TabClickEventDirective,
		ContentAnimateDirective,
		StickyDirective,
		// pipes
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		SafePipe,
		FirstLetterPipe,
		InlineSVGDirective,
		HasRoleDirective
	],
	providers: [UniqueColorPipe]
})
export class CoreModule {
}
