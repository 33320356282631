import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { map, tap, withLatestFrom, mergeMap, switchMap, catchError, filter, take } from 'rxjs/operators';
import * as VisitorActions from '../../conversations/store/visitors.actions';
import { AppState } from '../core.state';
import * as LayoutActions from './layout.actions';
import * as ToastrActions from './toastr.actions';
import { currentUser, currentOrganization } from '../auth';
import { CoreService } from '../services/core.service';
import { combineLatest, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { selectKbSearch, selectSelectedProperty, selectselectedCategory } from './layout.selectors';

@Injectable()
export class LayoutEffects {

    setTitle = createEffect(() => this.actions$.pipe(
        ofType(LayoutActions.setTitle),
        tap(s => {
            this.titleService.setTitle(s.title);
        })
    ), {dispatch: false});

    // Whenever the view mode change retrieve the new list
    /*setViewMode = createEffect(() => this.actions$.pipe(
        ofType(LayoutActions.setSelectedViewMode),
        map( () => VisitorActions.AllVisitorsRequested({paging: {page: 0}}) )  
    ));*/

    setProperty = createEffect(() => this.actions$.pipe(
        ofType(LayoutActions.setSelectedProperty),
        map( () => VisitorActions.AllVisitorsRequested({paging: {page: 0}}) )  
    ));

    saveFeedback = createEffect(() => this.actions$.pipe(
        ofType(LayoutActions.SaveFeedbackRequest),
        withLatestFrom(this.store.select(currentUser),
                        this.store.select(currentOrganization)),
        mergeMap( ([action, user, org]) => {
            const feedback = {
                ...action.feedback,
                user_id: user.id,
                org_id: org.id
            };

            return this.coreService.saveFeedback(feedback).pipe(
                switchMap(res => [
                    LayoutActions.SaveFeedbackSuccess(),
                    ToastrActions.showToastrSuccess( {message: 'Thanks for sharing your feedback', title: ''})
                ]),
                catchError((error: HttpErrorResponse) => of(
                    LayoutActions.SaveFeedbackFailed(),
                    ToastrActions.showToastrError({message: error.error , title: 'Something went wrong'})
                ))
            );
        })  
    ));

    getArticlesFromCategory$ = createEffect(() => this.actions$.pipe(
        ofType(LayoutActions.setSelectedCategory),
        mergeMap(action => combineLatest([
                of(action),
                this.store.pipe(select(selectKbSearch), take(1)),
                this.store.pipe(select(selectSelectedProperty), take(1))
            ])
        ),
        mergeMap( ([action, search, property_id])  => this.coreService.getArticles(search, action.category_id, property_id).pipe(
            switchMap(res => [
                LayoutActions.ArticlesRequestSuccess({articles: res.model}),
            ]),
            catchError((error: HttpErrorResponse) => of(
                ToastrActions.showToastrError({message: "Eror while retrieving the list of articles" , title: 'Something went wrong'})
            ))
        ))
    ));

    getArticlesFromSearch$ = createEffect(() => this.actions$.pipe(
        ofType(LayoutActions.SearchArticle),
        mergeMap(action => combineLatest([
                of(action),
                this.store.select(selectselectedCategory),
                this.store.pipe( select(currentOrganization), filter(o => o != null), take(1) ),
                this.store.pipe(select(selectSelectedProperty))
            ])
        ),
        mergeMap( ([action, category, org, property_id])  => this.coreService.getArticles(action?.search, category?.id ?? 0, property_id).pipe(
            switchMap(res => [
                LayoutActions.ArticlesRequestSuccess({articles: res.model}),
            ]),
            catchError((error: HttpErrorResponse) => of(
                ToastrActions.showToastrError({message: "Eror while retrieving the list of articles" , title: 'Something went wrong'})
            ))
        ))
    ));

    getArticle$ = createEffect(() => this.actions$.pipe(
        ofType(LayoutActions.setSelectedArticle),
        filter(action => (action.article_id != null) && (action.article_id > 0) ),
        mergeMap( action  => this.coreService.getArticle(action.article_id).pipe(
            switchMap(res => [
                LayoutActions.SingleArticleRequestSuccess({article: res.model}),
            ]),
            catchError((error: HttpErrorResponse) => of(
                ToastrActions.showToastrError({message: "Eror while retrieving the list of articles" , title: 'Something went wrong'})
            ))
        ))
    ));

    getAllCustomView$ = createEffect(() => this.actions$.pipe(
        ofType(LayoutActions.CustomsViewRequest),
        mergeMap(action => combineLatest( [
                of(action),
                this.store.pipe( select(currentOrganization), filter(o => o != null), take(1) )
            ])
        ),
        mergeMap( ([action, org])  => this.coreService.getCustomViews(org.id).pipe(
            switchMap(res => [
                LayoutActions.CustomsViewSuccess({custom_views: res.model}),
            ]),
            catchError((error: HttpErrorResponse) => of(
                ToastrActions.showToastrError({message: "Eror while retrieving your custom view" , title: 'Something went wrong'})
            ))
        ))
    ));

    getCustomView$ = createEffect(() => this.actions$.pipe(
        ofType(LayoutActions.LoadViewRequest),
        mergeMap( action  => this.coreService.getCustomView(action.id).pipe(
            switchMap(res => [
                LayoutActions.LoadViewSuccess({custom_view: res.model}),
            ]),
            catchError((error: HttpErrorResponse) => of(
                ToastrActions.showToastrError({message: "Eror while retrieving custom view details" , title: 'Something went wrong'})
            ))
        ))
    ));

    saveCustomView$ = createEffect(() => this.actions$.pipe(
        ofType(LayoutActions.SaveViewRequest),
        withLatestFrom(this.store.select(currentOrganization)),
        mergeMap( ([action, org])  => this.coreService.saveCustomView(action.custom_view, org.id).pipe(
            switchMap(res => [
                LayoutActions.SaveViewSuccess({custom_view: res.model}),
                ToastrActions.showToastrSuccess( {message: 'Custom View was successfully saved.', title: ''})
            ]),
            catchError((error: HttpErrorResponse) => of(
                ToastrActions.showToastrError({message: "Eror while saving the custom view" , title: 'Something went wrong'})
            ))
        ))
    ));

    deleteCustomView$ = createEffect(() => this.actions$.pipe(
        ofType(LayoutActions.DeleteViewRequest),
        mergeMap( action  => this.coreService.deleteCustomView(action.id).pipe(
            switchMap(res => [
                LayoutActions.DeleteViewSuccess({custom_view: res.model}),
                ToastrActions.showToastrSuccess( {message: 'Custom View was successfully deleted.', title: ''})
            ]),
            catchError((error: HttpErrorResponse) => of(
                ToastrActions.showToastrError({message: "Eror while deleting the custom view" , title: 'Something went wrong'})
            ))
        ))
    ));

    ngrxOnInitEffects(): Action {
        return { type: LayoutActions.SearchArticle.type };
    }

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private titleService: Title,
        private coreService: CoreService
    ) { }

}