import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable } from 'rxjs';
import { Paging } from "../../models/page.model";
import { Visitors, VisitorsResponse } from "../../models";
import { ConversationSummaryResponse, SingleTicketResponse, Ticket, TicketsResponse, TicketsViewMode } from "../models/ticket.model";
import { ReplyResponse, TicketReply } from "../models/ticketreply.model";


const API_URL = environment.apiUrl;

@Injectable({
	providedIn: 'root'
})
export class TicketsService {

	constructor(private http: HttpClient) {
	}

	public getTickets( property_id: number, view: TicketsViewMode, paging: Paging ) : Observable<TicketsResponse>  {
		return this.http.get<TicketsResponse>(
			`${API_URL}/api/tickets?property_id=${property_id}&page=${paging.page}&limit=${environment.tickets_pagesize}&sort=${paging.sort}&dir=${paging.dir}&search=${paging.search}&view=${view}`
		);
	}

	public getTicket(ticket_id: number) : Observable<SingleTicketResponse>  {
		return this.http.get<SingleTicketResponse>(
			`${API_URL}/api/tickets/${ticket_id}`
		);
	}

	public saveTicket(ticket: Partial<Ticket> ): Observable < SingleTicketResponse > {
		return this.http.post<SingleTicketResponse> (`${API_URL}/api/tickets`, ticket);
	}

	public updateTicket(id: number, ticket: Partial<Ticket> ): Observable < SingleTicketResponse > {
		return this.http.post<SingleTicketResponse> (`${API_URL}/api/tickets`, {id: id, ...ticket});
	}

    public deleteTicket(id: number) : Observable<SingleTicketResponse>  {
        return this.http.delete<SingleTicketResponse>(`${API_URL}/api/tickets/${id}`);
    }

	public saveReply(reply: TicketReply ): Observable < ReplyResponse > {
		return this.http.post<ReplyResponse> (`${API_URL}/api/tickets/replies`, reply);
	}

	public getRecipient(email: string, property_id: number): Observable<VisitorsResponse>{
		return this.http.get<VisitorsResponse>(
			`${API_URL}/api/tickets/recipients?email=${email}&property_id=${property_id}`
		);
	}

	public uploadAttachment(file: FormData): Observable<HttpEvent<SingleTicketResponse>> {
		return this.http.post<SingleTicketResponse>(`${API_URL}/api/tickets/uploadfile`, file, { reportProgress: true, observe: 'events' }); 
	  }

	  public summarize(message_id: string) : Observable<ConversationSummaryResponse>  {
		return this.http.get<ConversationSummaryResponse>(
			`${API_URL}/api/tickets/summarize?message_id=${message_id}`
		);
	}

}