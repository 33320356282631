import { createReducer, on, State, Action } from '@ngrx/store';
import * as LayoutActions from './layout.actions';
import { KbArticle } from '../../kb/models/kbarticle.model';
import { cloneDeep, filter } from 'lodash-es';
import { CustomView } from '../../models';

export interface LayoutState{
    selectedShortcut: string,
    scrollToSession: string,
    title: string,
    desc: string,
    selectedProperty: number,
    selectedViewMode: string | number,
    selectedVisitorOpen: string,
    selectedVisitorMine: string,
    selectedVisitorClosed: string,
    infoTab: string;
    isMessagePrivate: boolean;

    selectedKbCategory: number,
    selectedKbArticle: number,
    searchKb: string,
    articlesFiltered: KbArticle[],
    pendingArticle: number,

    newTickets: number,
    newMessages: number,

    isHubDisconnected: boolean,

    view_save_loading: boolean,
    customViews: CustomView[]
}

const initialState: LayoutState = { 
    selectedShortcut: "",
    scrollToSession: "",
    title: "Charla",
    desc: "",
    selectedProperty: 0,
    selectedViewMode: 'open',
    selectedVisitorOpen: '',
    selectedVisitorMine: '',
    selectedVisitorClosed: '',
    infoTab: "info",
    isMessagePrivate: false,

    selectedKbCategory: 0,
    selectedKbArticle: 0,
    searchKb: "",
    articlesFiltered: [],
    pendingArticle: 0,

    newTickets: 0,
    newMessages: 0,

    isHubDisconnected: false,

    view_save_loading: false,
    customViews: []

};

const reducer = createReducer(
    initialState,
    on(LayoutActions.setShortcut, (state, { shortcut }) => (
        { ...state, selectedShortcut: shortcut }
    )),
    on(LayoutActions.setInfoTab, (state, { tab }) => (
        { ...state, infoTab: tab }
    )),
    on(LayoutActions.scrollToSession, (state, { id }) => (
        { ...state, scrollToSession: id }
    )),
    on(LayoutActions.setTitle, (state, { title, desc }) => (
        { ...state, title: title, desc: desc }
    )),
    on(LayoutActions.setSelectedProperty, (state, { id }) => (
        { ...state, selectedProperty: id }
    )),
    on(LayoutActions.setSelectedViewMode, (state, { mode }) => (
        { ...state, selectedViewMode:mode }
    )),
    on(LayoutActions.persistVisitorSelection, (state, { visitor_id, view }) => {
        if (view == 'open') return {...state, selectedVisitorOpen: visitor_id};
        else if (view == 'mine') return {...state, selectedVisitorMine: visitor_id};
        else if (view == 'closed') return {...state, selectedVisitorClosed: visitor_id};
    }      
    ),
    on(LayoutActions.setSelectedCategory, (state, { category_id }) => (
        { ...state, selectedKbCategory: category_id }
    )),
    on(LayoutActions.setSelectedArticle, (state, { article_id }) => (
        { ...state, selectedKbArticle: article_id }
    )),

    on(LayoutActions.SearchArticle, (state, { search }) => (
        { ...state, searchKb: search }
    )),
    on(LayoutActions.ArticlesRequestSuccess, (state, { articles }) => (
        { ...state, articlesFiltered: cloneDeep(articles) }
    )),
    on(LayoutActions.SingleArticleRequestSuccess, (state, {article}) => {

        // create a shallow copy of the old articles array
        const nkbarticles =  cloneDeep(state.articlesFiltered);
        var ar = nkbarticles.find(q => q.id == article.id);
        ar.article = article.article;

        return {
            ...state,
            articlesFiltered: nkbarticles
        }
    }),

    on(LayoutActions.insertArticle, (state, { article_id }) => (
        { ...state, pendingArticle: article_id }
    )),

    on(LayoutActions.NewTicketsIndicator, (state) => (
        { ...state, newTickets: state.newTickets + 1 }
    )),
    on(LayoutActions.ResetNewTicketsIndicator, (state) => (
        { ...state, newTickets: 0 }
    )),

    on(LayoutActions.HubDisconnected, (state, {is_disconnected}) => (
        { ...state, isHubDisconnected: is_disconnected }
    )),

    on(LayoutActions.TogglePrivateMode, (state) => (
        {...state, isMessagePrivate: !(state.isMessagePrivate)}
    )),

    on(LayoutActions.CustomsViewSuccess, (state, {custom_views}) => (
        { ...state, customViews: custom_views }
    )),

    on(LayoutActions.SaveViewRequest, (state) => (
        { ...state, view_save_loading: true }
    )),
    on(LayoutActions.SaveViewSuccess, (state, { custom_view }) => (
        { ...state, 
            customViews: filter(state.customViews, v => v.id != custom_view.id).concat(custom_view),
            view_save_loading: false }
    )),
    on(LayoutActions.SaveViewFailed, (state) => (
        { ...state, view_save_loading: false }
    )),
    on(LayoutActions.LoadViewSuccess, (state, { custom_view }) => {
        
        var cvs = cloneDeep(state.customViews);
        var index = cvs.findIndex(v => v.id == custom_view.id);
        cvs[index] = cloneDeep(custom_view);

        return { ...state, 
            customViews: cvs,
            view_save_loading: false 
        }
    }),
    on(LayoutActions.DeleteViewSuccess, (state, {custom_view}) => (
        { ...state, 
            customViews: filter(state.customViews, v => v.id != custom_view.id),
        }
    ))

);

export function layoutReducer(state: LayoutState | undefined, action: Action) {
    return reducer(state, action);
}