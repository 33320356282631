import { EntityAdapter, EntityState, Update, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Paging } from 'src/app/models/page.model';
import { environment } from '../../../environments/environment';
import { Ticket, TicketStatus } from '../models/ticket.model';
import * as TicketsActions from './tickets.actions';
import { cloneDeep } from 'lodash-es';
import { id } from '@swimlane/ngx-datatable';
import moment from 'moment';

export interface TicketsState extends EntityState<Ticket>{
    selectedProperty: number,
    selectedTicket: number,
    isTicketsLoading: boolean,
    save_loading: boolean,
    delete_loading: boolean,
    sending: boolean,
    isLoaded: boolean,
    paging: Paging,
    view: number;
}

export const adapter : EntityAdapter<Ticket> = createEntityAdapter<Ticket>({
    sortComparer: sortByLastUpdated
});

function sortByLastUpdated(e1: Ticket, e2: Ticket) {

    // handle cases where visitor does not have a last message
    let d1, d2;
    d1 = moment( e1.last_updated_at.replace('T', ' '), 'YYYY-MM-DD HH:mm:ss').toDate().getTime() 
    d2 = moment( e2.last_updated_at.replace('T', ' '), 'YYYY-MM-DD HH:mm:ss').toDate().getTime() 

    if (d1 > d2) return -1;
    else if (d2 > d1) return 1;
    else return 0; 
}

const initialState: TicketsState = adapter.getInitialState({ 
    selectedProperty: 0,
    selectedTicket: 0,
    isTicketsLoading: false,
    save_loading: false,
    delete_loading: false,
    sending: false,
    isLoaded: false,
    paging: {
        limit: environment.datatable_pagesize,
        total: 0,
        page: 0,
        sort: "lastupdatedat",
        dir: "desc",
        search: ""
    },
    view: 3
});

const reducer = createReducer(
    initialState,
    on(
        TicketsActions.TicketsRequested,
        (state, { paging }) => ({  ...state, 
                                        isTicketsLoading: true
                                    })
    ),
    on(
        TicketsActions.TicketsPropertySelected,
        (state, { property_id }) => ({  ...state, 
                                        selectedProperty: property_id
                                    })
    ),
    on(
        TicketsActions.TicketsRequestSuccess,
        (state, {tickets} ) => {
            return (tickets.pageNumber == 0) ?
                adapter.setAll(tickets.model, { ...state, 
                    paging: {
                        total: tickets.itemsCount,
                        limit: environment.datatable_pagesize,
                        page: tickets.pageNumber,
                        sort: tickets.sort,
                        dir: tickets.dir,
                        search: tickets.search ?? ""
                    },
                    isTicketsLoading: false,
                    isLoaded: true
                })
                : adapter.addMany(tickets.model, { ...state,        
                    paging: {
                        total: tickets.itemsCount,
                        limit: environment.datatable_pagesize,
                        page: tickets.pageNumber,
                        sort: tickets.sort,
                        dir: tickets.dir,
                        search: tickets.search ?? ""
                    },
                    isTicketsLoading: false,
                    isLoaded: true
            })
        }
    ),
    on(
        TicketsActions.SingleTicketRequestSuccess,
        (state, { ticket }) => (adapter.upsertOne(ticket, state))
    ),
    on(
        TicketsActions.TicketSaveRequest,
        (state, { ticket }) => ({...state, save_loading: true})
    ),
    on(
        TicketsActions.TicketSaveSuccess,
        (state, { ticket }) => (adapter.upsertOne(ticket, {...state, save_loading: false}))
    ),
    on(
        TicketsActions.TicketSaveFailed,
        (state, { message }) => ({...state, save_loading: false})
    ),

    on(
        TicketsActions.TicketDeleteRequest,
        (state, { ticket_id }) => ({...state, delete_loading: true})
    ),
    on(
        TicketsActions.TicketDeleteSuccess,
        (state, { ticket_id }) => (adapter.removeOne(ticket_id, {...state, delete_loading: false}))
    ),
    on(
        TicketsActions.TicketRemove,
        (state, { ticket_id }) => (adapter.removeOne(ticket_id, state))
    ),
    on(
        TicketsActions.TicketDeleteFailed,
        (state, { message }) => ({...state, delete_loading: true})
    ),
    on(
        TicketsActions.MarkTicketRead,
        (state, { ticket_id }) => {
            let updated_ticket: Update<Ticket> = {
                id: ticket_id,
                changes: { unread: false }
            }
            return adapter.updateOne(updated_ticket, state);
        }  
    ),
    on(
        TicketsActions.MarkTicketUnread,
        (state, { ticket_id }) => {
            let updated_ticket: Update<Ticket> = {
                id: ticket_id,
                changes: { unread: true }
            }
            return adapter.updateOne(updated_ticket, state);
        }  
    ),

    on(
        TicketsActions.ReplySaveRequest,
        (state, { reply }) => ({...state, sending: true})
    ),
    on(
        TicketsActions.ReplySaveFailed,
        (state, { message }) => ({...state, sending: false})
    ),
    on(
        TicketsActions.ReplySaveSuccess,
        (state, { reply }) => {

            let replies = cloneDeep(state.entities[reply.ticket_id]).replies;
            replies.unshift(reply);

            let updated_ticket: Update<Ticket> = {
                id: reply.ticket_id,
                changes: {
                    replies: replies,
                    status: TicketStatus.InProgress
                }
            };

            return adapter.updateOne(updated_ticket, {...state, sending: false});
        }
    ),
    on(
        TicketsActions.AssigneeSaveSuccess,
        (state, { ticket }) => (adapter.updateOne(ticket, state))
    ),
    on(
        TicketsActions.TagsSaveSuccess,
        (state, { ticket }) => (adapter.updateOne(ticket, state))
    ),
    on(
        TicketsActions.StatusSaveSuccess,
        (state, { ticket }) => (adapter.updateOne(ticket, state))
    ),
    on(
        TicketsActions.PrioritySaveSuccess,
        (state, { ticket }) => (adapter.updateOne(ticket, state))
    ),
    on(
        TicketsActions.SetSelectedTicket,
        (state, { id }) => ({...state, selectedTicket: id})
    ),

    on(
        TicketsActions.SetView,
        (state, { view }) => ({...state, view: view})
    ),

    on(
        TicketsActions.TicketContactSaveSuccess,
        (state, { contact, ticket_id }) => {

            let updated_ticket: Update<Ticket> = {
                id: ticket_id,
                changes: {
                    recipient: contact,
                }
            };

            return adapter.updateOne(updated_ticket, state);
        }
    ),
);

export function TicketsStateReducer(state: TicketsState | undefined, action: Action) {
    return reducer(state, action);
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
  
  } = adapter.getSelectors();
