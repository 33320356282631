import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Visitor, VisitorResponse, Visitors, VisitorsResponse } from '../../models';
import { Paging } from '../../models/page.model';
import { tap } from 'rxjs/operators';

const API_URL = environment.apiUrl;

@Injectable({
	providedIn: 'root'
})
export class ContactsService {

	constructor(private http: HttpClient) {
	}


	public getContact(contact_id: string) : Observable<VisitorResponse>  {
		return this.http.get<VisitorResponse>(
			`${API_URL}/api/contacts/${contact_id}`
		);
	}

	public getContacts( property_ids, paging: Paging ) : Observable<Visitors>  {
		return this.http.get<VisitorsResponse>(
			`${API_URL}/api/contacts?property_ids=${property_ids}&page=${paging.page}&limit=${environment.datatable_pagesize}&sort=${paging.sort}&dir=${paging.dir}&search=${paging.search}`
		);
	}

	public saveContact(id: string | number, contact: Partial<Visitor> ): Observable < VisitorResponse > {
		return this.http.put < VisitorResponse > (`${API_URL}/api/contacts/${id}`, contact);
	}

	public delete(id: string): Observable < VisitorResponse > {
		return this.http.delete < VisitorResponse > (`${API_URL}/api/contacts/${id}`);
	}

	public exportContacts( file_type: string, property_ids, paging: Paging ) : Observable<Object>  {
		return this.http.get(
			`${API_URL}/api/contacts/export?file_type=${file_type}&property_ids=${property_ids}`,
			{ responseType: 'blob' as 'json' }
		).pipe(
			tap( (response: any) => {
				let dataType = response.type;
				console.log("Type is " + dataType);
				this.downLoadFile(response, dataType)
			})
		);
	}

    /**
     * Method is use to download file.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
    downLoadFile(data: any, type: string) {
        let blob = new Blob([data], { type: type});
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            alert( 'Please disable your Pop-up blocker and try again.');
        }
    }

}
