import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ 
    name: 'ucolor',
    standalone: true
})
export class UniqueColorPipe implements PipeTransform {

    public avatarColors: string[];

    constructor() {
        this.avatarColors = [
            '#1abc9c',
            '#3498db',
            '#f1c40f',
            '#8e44ad',
            '#e74c3c',
            '#d35400',
            '#2c3e50',
            '#7f8c8d'
          ];
    }
  

	transform(val: string) {
        if (!val) return '';
        
        return this.getRandomColor(val);
    }

    // Added from ngx-avatar style of coloring
    // @see https://github.com/HaithemMosbahi/ngx-avatar/blob/22e9715e6c6499d22997982b2b46ca3323cbe683/projects/ngx-avatar/src/lib/avatar.service.ts
    public getRandomColor(avatarText: string): string {
        if (!avatarText) {
          return 'transparent';
        }
        const asciiCodeSum = this.calculateAsciiCode(avatarText);
        return this.avatarColors[asciiCodeSum % this.avatarColors.length];
    }

    private calculateAsciiCode(value: string): number {
        return value
            .split('')
            .map(letter => letter.charCodeAt(0))
            .reduce((previous, current) => previous + current);
    }

}
