import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { AppState } from "../../core/core.state";
import { TicketsService } from "../services/tickets.service";
import * as TicketsActions from './tickets.actions';
import { selectTicketsIsLoaded, selectTicketsPaging, selectTicketsProperty, selectTicketsState, selectView } from "./tickets.selectors";
import { catchError, filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { merge } from "lodash-es";
import * as ToastrActions from '../../core/store/toastr.actions';
import { HttpErrorResponse } from "@angular/common/http";
import { combineLatest, of } from "rxjs";
import { Ticket, TicketsViewMode } from "../models/ticket.model";
import { Update } from "@ngrx/entity";
import { Router } from "@angular/router";
import { currentAvailableStatus } from "../../core/auth/auth.selectors";
import { ConversationsService } from "../../core/services/conversations.service";
import { NewTicketsIndicator } from "../../core/store/layout.actions";
import { VisitorRequested } from "../../conversations/store/visitors.actions";
import { ContactsService } from "../../contacts/services/contacts.service";
import { Visitor } from "../../models";


@Injectable()
export class TicketsEffects {

    ticketsRequest = createEffect(() => this.actions$.pipe(
        ofType(TicketsActions.TicketsRequested),
        withLatestFrom(
                this.store.select(selectTicketsPaging),
                this.store.select(selectTicketsProperty),
                this.store.select(selectView)
            ),
        mergeMap( ([action, existing_paging, property_id, view_mode ]) => {
            
            var p = merge( {}, existing_paging, action.paging);
            return this.ticketService.getTickets(property_id, view_mode, p)
            }),
        map( a => TicketsActions.TicketsRequestSuccess( { tickets: a} ) )
    ));

    ticketRequest = createEffect(() => this.actions$.pipe(
        ofType(TicketsActions.SingleTicketsRequest),
        switchMap(action => combineLatest([
            of(action),
            this.store.pipe( select(selectTicketsIsLoaded), filter(u => u == true), take(1))
        ])),
        mergeMap( ([action, isLoaded])  => this.ticketService.getTicket(action.ticket_id).pipe(
            switchMap( res => [ 
                TicketsActions.SingleTicketRequestSuccess({ ticket: {...res.model, unread: false}}),
                VisitorRequested({visitor_id: res.model.recipient.id})
            ]),
            catchError((er: HttpErrorResponse) => of(
                ToastrActions.showToastrError({message: 'Problems while retrieving ticket details. The error has been reported.' , title: 'Something went wrong'})
            ))
        ))
    
    ));

    ticketContactSaveRequest = createEffect(() => this.actions$.pipe(
        ofType(TicketsActions.TicketContactSaveRequest),
        mergeMap( action => 
            this.contactsService.saveContact(action.contact.id, action.contact.changes).pipe(
                switchMap (res =>  [
                    TicketsActions.TicketContactSaveSuccess({contact: res.model, ticket_id: action.ticket_id}),
                    ToastrActions.showToastrSuccess({message: 'Contact details were updated successfully.' , title: 'Contact Updated'})              
                ]),
                catchError((er: HttpErrorResponse) => of(
                    ToastrActions.showToastrError({message: 'Problems while updating the contact info. The error has been reported.' , title: 'Something went wrong'})
                ))
            )
        )
    ));

    newTicketReceived = createEffect(() => this.actions$.pipe(
        ofType(TicketsActions.NewTicketReceived),
        switchMap(action => combineLatest([
            of(action),
            this.store.pipe( select(selectTicketsIsLoaded), filter(u => u == true), take(1))
        ])),
        mergeMap( ([action, isLoaded, ])  => this.ticketService.getTicket(action.ticket_id).pipe(
            withLatestFrom(this.store.select(currentAvailableStatus)),
            switchMap( ([res, isAgentOnline]) => {
                if (isAgentOnline){
                    this.converseService.play_notification();
                    this.converseService.flash_notification("New Ticket");
                    this.converseService.show_notification(
                        res.model.subject, 
                        "New Ticket #" + res.model.public_id + " Received"
                    );
                }

                if (! (this.router.url.includes("/tickets"))){
                    this.store.dispatch(NewTicketsIndicator());
                }

                return [
                    TicketsActions.SingleTicketRequestSuccess({ ticket: res.model}),
                    TicketsActions.MarkTicketUnread({ticket_id: res.model.id})
                ]
            }),
            catchError((er: HttpErrorResponse) => of(
                ToastrActions.showToastrError({message: 'Problems while retrieving ticket details. The error has been reported.' , title: 'Something went wrong'})
            ))
        ))
    ));

    newReplyReceived = createEffect(() => this.actions$.pipe(
        ofType(TicketsActions.NewReplyReceived),
        switchMap(action => combineLatest([
            of(action),
            this.store.pipe( select(selectTicketsIsLoaded), filter(u => u == true), take(1))
        ])),
        mergeMap( ([action, isLoaded, ])  => this.ticketService.getTicket(action.ticket_id).pipe(
            withLatestFrom(this.store.select(currentAvailableStatus)),
            switchMap( ([res, isAgentOnline]) => {
                if (isAgentOnline){
                    this.converseService.play_notification();
                    this.converseService.flash_notification("New Reply");
                    this.converseService.show_notification(
                        res.model.subject, 
                        "New Reply Received for Ticket  #" + res.model.public_id 
                    );
                }

                if (! (this.router.url.includes("/tickets"))){
                    this.store.dispatch(NewTicketsIndicator());
                }

                return [
                    TicketsActions.SingleTicketRequestSuccess({ ticket: res.model}),
                    TicketsActions.MarkTicketUnread({ticket_id: res.model.id})
                ]
            }),
            catchError((er: HttpErrorResponse) => of(
                ToastrActions.showToastrError({message: 'Problems while retrieving ticket details. The error has been reported.' , title: 'Something went wrong'})
            ))
        ))
    ));

    ticketSaveRequest = createEffect(() => this.actions$.pipe(
        ofType(TicketsActions.TicketSaveRequest),
        mergeMap(action => this.ticketService.saveTicket(action.ticket).pipe(
            switchMap(res => [
                TicketsActions.TicketSaveSuccess({ticket: res.model}),
                ToastrActions.showToastrSuccess({message: 'Ticket was successfully saved.' , title: ''})
            ]),
            catchError((er: HttpErrorResponse) => of(
                TicketsActions.TicketSaveFailed({message: 'Problems while saving the article' }),
                ToastrActions.showToastrError({message: 'Problems while saving article. The error has been reported.' , title: 'Something went wrong'})
            ))
        ))
    ));

    ticketSaveSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(TicketsActions.TicketSaveSuccess),
        tap( (action) => this.router.navigate(['/tickets', action.ticket.id]))
    ), {dispatch: false});

    ticketDeleteRequest = createEffect(() => this.actions$.pipe(
        ofType(TicketsActions.TicketDeleteRequest),
        mergeMap(action => this.ticketService.deleteTicket(action.ticket_id).pipe(
            switchMap(res => [
                TicketsActions.TicketDeleteSuccess({ticket_id: action.ticket_id}),
                ToastrActions.showToastrSuccess({message: 'Ticket was successfully deleted.' , title: ''})
            ]),
            catchError((er: HttpErrorResponse) => of(
                TicketsActions.TicketDeleteFailed({message: 'Problems while deleting the ticket' }),
                ToastrActions.showToastrError({message: 'Problems while deleting the ticket. The error has been reported.' , title: 'Something went wrong'})
            ))
        ))
    ));

    ticketDeleteSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(TicketsActions.TicketDeleteSuccess),
        tap( (action) => this.router.navigate(['/tickets']))
    ), {dispatch: false});


    replySaveRequest = createEffect(() => this.actions$.pipe(
        ofType(TicketsActions.ReplySaveRequest),
        mergeMap(action => this.ticketService.saveReply(action.reply).pipe(
            switchMap(res => [
                TicketsActions.ReplySaveSuccess({reply: res.model}),
                ToastrActions.showToastrSuccess({message: 'Reply was successfully sent.' , title: ''})
            ]),
            catchError((er: HttpErrorResponse) => of(
                TicketsActions.ReplySaveFailed({message: 'Problems while sending Reply' }),
                ToastrActions.showToastrError({message: 'Problems while sending the reply. The error has been reported.' , title: 'Something went wrong'})
            ))
        ))
    ));

    AssigneeSaveRequest = createEffect(() => this.actions$.pipe(
        ofType(TicketsActions.AssigneeSaveRequest),
        mergeMap(action => this.ticketService.updateTicket( action.ticket.id as number, action.ticket.changes).pipe(
            switchMap(res => [
                TicketsActions.AssigneeSaveSuccess({ticket: action.ticket}),
                ToastrActions.showToastrSuccess({message: 'Ticket Assignees were successfully updated.' , title: ''})
            ]),
            catchError((er: HttpErrorResponse) => of(
                TicketsActions.AssigneeSaveFailed({message: 'Problems while updating the ticket assignees' }),
                ToastrActions.showToastrError({message: 'Problems while updating assignees. The error has been reported.' , title: 'Something went wrong'})
            ))
        ))
    ));

    PrioritySaveRequest = createEffect(() => this.actions$.pipe(
        ofType(TicketsActions.PrioritySaveRequest),
        mergeMap(action => this.ticketService.updateTicket( action.ticket.id as number, action.ticket.changes).pipe(
            switchMap(res => [
                TicketsActions.PrioritySaveSuccess({ticket: action.ticket}),
                ToastrActions.showToastrSuccess({message: 'Priority was successfully updated.' , title: ''})
            ]),
            catchError((er: HttpErrorResponse) => of(
                TicketsActions.PrioritySaveFailed({message: 'Problems while updating the ticket priority' }),
                ToastrActions.showToastrError({message: 'Problems while updating assignees. The error has been reported.' , title: 'Something went wrong'})
            ))
        ))
    ));

    StatusSaveRequest = createEffect(() => this.actions$.pipe(
        ofType(TicketsActions.StatusSaveRequest),
        mergeMap(action => this.ticketService.updateTicket( action.ticket.id as number, action.ticket.changes).pipe(
            withLatestFrom(this.store.select(selectView)),
            switchMap( ([res, view]) => {
                
                const ViewMode = TicketsViewMode;

                if (view == ViewMode.Open || view == ViewMode.InProgress){
                    return [
                        TicketsActions.TicketRemove({ticket_id: action.ticket.id as number}),
                        TicketsActions.StatusSaveSuccess({ticket: action.ticket}),
                        ToastrActions.showToastrSuccess({message: 'Ticket Status was successfully updated.' , title: ''})        
                        
                    ];
                } else {
                    return [
                        TicketsActions.StatusSaveSuccess({ticket: action.ticket}),
                        ToastrActions.showToastrSuccess({message: 'Ticket Status was successfully updated.' , title: ''})        
                    ];
                }
            }),
            catchError((er: HttpErrorResponse) => of(
                TicketsActions.StatusSaveFailed({message: 'Problems while updating the ticket status' }),
                ToastrActions.showToastrError({message: 'Problems while updating status. The error has been reported.' , title: 'Something went wrong'})
            ))
        ))
    ));

    TagsSaveRequest = createEffect(() => this.actions$.pipe(
        ofType(TicketsActions.TagsSaveRequest),
        mergeMap(action => this.ticketService.updateTicket( action.ticket.id as number, action.ticket.changes).pipe(
            switchMap(res => [
                TicketsActions.TagsSaveSuccess({ticket: action.ticket}),
                ToastrActions.showToastrSuccess({message: 'Tags were successfully updated.' , title: ''})
            ]),
            catchError((er: HttpErrorResponse) => of(
                TicketsActions.TagsSaveFailed({message: 'Problems while updating the ticket tags' }),
                ToastrActions.showToastrError({message: 'Problems while updating tags. The error has been reported.' , title: 'Something went wrong'})
            ))
        ))
    ));

    propertySelected = createEffect( () => this.actions$.pipe(
        ofType(TicketsActions.TicketsPropertySelected),
        withLatestFrom(this.store.select(selectTicketsPaging)),
        map( ([action, existing_paging]) => TicketsActions.TicketsRequested({
                paging: merge( {}, existing_paging, {page : 0} ) 
            }) )
    ));

    conversationSummarizationRequest = createEffect(() => this.actions$.pipe(
        ofType(TicketsActions.ConversationSummarizationRequest),
        mergeMap(action => this.ticketService.summarize(action.message_id).pipe(
            switchMap(res => [
                TicketsActions.ConversationSummarizationSuccess({summary: res.model.summary}),
            ]),
            catchError((er: HttpErrorResponse) => of(
                ToastrActions.showToastrError({message: 'A problem occured while genereating a conversation summary.' , title: 'Something went wrong'})
            ))
        ))
    ));

    constructor(
        private ticketService: TicketsService,
        private actions$: Actions,
        private store: Store<AppState>,
        private router: Router,
        private converseService: ConversationsService,
        private contactsService: ContactsService
    ) { }

}