import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, switchMap, catchError, tap, skipWhile, take, withLatestFrom } from 'rxjs/operators';
import { of, empty, combineLatest, EMPTY } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import * as ActivityActions from './activity.actions';
import * as VisitorsActions from './visitors.actions';
import * as ToastrActions  from '../../core/store/toastr.actions';
import { ConversationsService } from '../../core/services/conversations.service';
import { AppState } from '../../core/core.state';
import { selectCurrentVisitor, selectSelectedVisitor, selectVisitorById } from './visitors.selectors';
import { Visitor } from '../../models';
import { currentAvailableStatus } from '../../core/auth/auth.selectors';
import _ from 'lodash-es';

@Injectable()
export class ActivityEffects {

    loadVisit = createEffect(() => this.actions$.pipe(
        ofType(ActivityActions.VisitRequested),
        mergeMap(action => this.converseService.getVisit( action.id )),
        switchMap( visit => {
            
            // if the new visit contains wc data, update the visitor as well
            if (visit.model.extra_wc?.items?.length > 0 || visit.model.extra_wc?.email || visit.model.extra_wc?.name ){
                
                
                const visitor_update: Update<Visitor> = {
                    id: visit.model.visitor_id,
                    changes: {
                        last_shop_visit: {...visit.model.extra_wc, created_at: visit.model.created_at},    
                    }
                };

                if (visit.model.extra_wc.email){
                    visitor_update.changes = {
                        ...visitor_update.changes, 
                        email: visit.model.extra_wc.email,
                        name: visit.model.extra_wc.email.substring(0, visit.model.extra_wc.email.lastIndexOf("@"))
                    };
                } 

                if (visit.model.extra_wc.name){
                    visitor_update.changes = {
                        ...visitor_update.changes, 
                        name: visit.model.extra_wc.name
                    };
                } 

                return [
                    ActivityActions.VisitLoaded( {Visit: visit.model} ),
                    VisitorsActions.VisitorUpdateSuccess({ visitor: visitor_update})
                ];
            }

            return [ActivityActions.VisitLoaded( {Visit: visit.model} )];
        })
    ));

    loadActivityOfVisitor = createEffect(() => this.actions$.pipe(
        ofType(ActivityActions.ActivitiesOfVisitorRequested),
        mergeMap(action => this.converseService.getActivities( action.visitor_id )),
        map( a => ActivityActions.ActivitiesOfVisitorLoaded( {Activities: a.model} ) )
    ));

    
    messageReceived$ = createEffect( () => this.actions$.pipe(
        ofType(ActivityActions.MessageActivityReceived),
        mergeMap(action => this.converseService.getMessage( action.message_id )),
        mergeMap( message => {

            // update the visitor last message date and content. Note 
            // that the below combineLatest won't fire because of the selectVisitorById
            // if we're not dispatching first
            this.store.dispatch(VisitorsActions.VisitorRequested( {
                visitor_id: message.model.visitor_id
            }));

            return combineLatest([
                    of(message.model),
                    this.store.pipe(
                        
                        select(selectVisitorById(message.model.visitor_id)),
                        skipWhile( v => (v == null) ),
                        take(1)
                    ),
                    this.store.pipe(
                        select(selectCurrentVisitor),
                        take(1)
                    ),
                    this.store.pipe( select(currentAvailableStatus), take(1))
                ])
        }),
        tap( ([m,v,s, a]) => {

            // Show notification if user is available and not isBot
            if (a == 1 && !m.is_bot){
                this.converseService.show_notification(
                    m.message, 
                    'Visitor #' + m.visitor_id.substr(0,2).toUpperCase() 
                );
                this.converseService.play_notification();
                this.converseService.flash_notification();
                this.converseService.loop_sound(v);
            }

            // increase the unread count if the visitor is not the one selected
            if ( m.visitor_id != s ) {
                const visitor_update: Update<Visitor> = {
                    id: m.visitor_id,
                    changes: { unread_count: (v.unread_count) ? v.unread_count + 1 : 1 }
                };                    
                this.store.dispatch( VisitorsActions.VisitorUnreadCount({visitor: visitor_update}) );
            }
        }),
        switchMap( ( [message, visitor,s, a]) => [
                ActivityActions.MessageActivityLoaded({Message: message}),
            ] )
    ));


    agentMessageReceived$ = createEffect( () => this.actions$.pipe(
        ofType(ActivityActions.AgentActivityReceived),
        mergeMap(action => this.converseService.getMessage( action.message_id )),
        mergeMap( res=> combineLatest([
            of(res.model),
            this.store.pipe( select(selectVisitorById(res.model.visitor_id)), take(1)),
            this.store.pipe( select(selectCurrentVisitor), take(1)),
        ])),
        mergeMap( ([message, visitor_message, selected_vid]) => {
            var r = [];
            if (message.visitor_id == selected_vid) {
                r.push(ActivityActions.MessageActivityLoaded({Message: message}));
            }

            if (visitor_message.agents != message.agent_id){
                const visitor_update: Update<Visitor> = {
                    id: visitor_message.id,
                    changes: { 
                        agents: this.appendAgent(message.agent_id, visitor_message.agents),
                        is_attended: true 
                    }
                }; 
                r.push(VisitorsActions.VisitorUpdateSuccess({visitor: visitor_update})); 
            }

            if (r.length == 0) r.push(EMPTY);
            return r;

        })
    ));

    emailSession = createEffect(() => this.actions$.pipe(
        ofType(ActivityActions.EmailSessionRequest),
        mergeMap(action => this.converseService.emailSession( action.session_id, action.visitor_id).pipe(
            switchMap(res => [
                ToastrActions.showToastrSuccess({message:'Session Transcript scheduled to be sent.', title: ''})
            ]),
            catchError( err => [
                ToastrActions.showToastrError({message: 'Error while scheduling sending.' , title: 'Something went wrong'})
            ])
            )
        ) 
    ));

    closeSession = createEffect(() => this.actions$.pipe(
        ofType(ActivityActions.CloseChatSessionRequest),
        mergeMap(action => this.converseService.closeSession(action.visitor_id).pipe(
            switchMap(res => [
                VisitorsActions.VisitorRemove({visitor_id: res.model.id}),
                VisitorsActions.VisitorSelected({visitor_id: ""})
            ]),
            catchError( err => [
                ToastrActions.showToastrError({message: 'Error closing the session.' , title: 'Something went wrong'})
            ])
            )
        )
    ));

    deleteSessions = createEffect(() => this.actions$.pipe(
        ofType(ActivityActions.DeleteSessionsRequest),
        mergeMap(action => this.converseService.deleteSessions(action.visitor_id).pipe(
            switchMap(res => [
                VisitorsActions.VisitorRemove({visitor_id: res.model.id}),
                VisitorsActions.VisitorSelected({visitor_id: ""})
            ]),
            catchError( err => [
                ToastrActions.showToastrError({message: 'Error deleting sessions.' , title: 'Something went wrong'})
            ])
            )
        )
    ));

    /* SignarR 
    ** @desc Send agent message to Signalr   
    */
   sendMessage$ = createEffect( () => this.actions$.pipe(
    ofType(ActivityActions.SendMessage),
    switchMap(action => combineLatest([
        of(action.Message),
        this.converseService.send(action.Message),
        this.store.pipe(select(selectVisitorById(action.Message.visitor_id)), take(1))
    ])),
    switchMap( ([m, res, v]) => {

            const visitor_update: Update<Visitor> = {
                id: v.id,
                changes: { 
                    is_attended: true,
                    agents: this.appendAgent(m.agent_id, v.agents)
                }
            };                    
        
            return [
                VisitorsActions.VisitorUpdateSuccess({visitor: visitor_update}),
                ActivityActions.MessageActivityLoaded({Message: m})
            ]

    })
    ));

    sendMessageFailed$ = createEffect( () => this.actions$.pipe(
        ofType(ActivityActions.SendMessageFailed),
        mergeMap(action => [
                ToastrActions.showToastrError({message: action.error , title: 'Something went wrong'})
            ]
        )
    ));

    appendAgent(agent, agents){

        // if agents is already empty return the new agent
        if (!agents) return agent;

        var ags = agents.split(",");
        if (!ags.includes(agent)) ags.push(agent);

        return ags.join(",");
    }

    constructor(
        private converseService: ConversationsService,
        private actions$: Actions,
        private store: Store<AppState>
        ) { 
        console.debug("ActivityEffects is constructed")
    }

}